<template>
  <b-form-group class="d-flex flex-column">
    <b-row>
      <b-col cols="6">
        <label
          for="form-country"
          class="form-label"
        >{{ $t('customerpopup.country-region') }}</label>
        <b-form-select
          class=" mb-2"
          v-model="addressDetails.country"
          :options="countryOptions"
          id="form-country"
        />
      </b-col>
      <b-col cols="6">
        <label
          for="form-state"
          class="form-label"
        >{{ $t('customerpopup.state') }}</label>
        <b-form-select
          v-if="addressDetails.country === 'US'"
          v-model="addressDetails.state"
          :options="statesOptions"
          id="form-state"
        />
        <b-input
          v-else
          class="mb-2"
          v-model="addressDetails.state"
          id="form-state"
        />
      </b-col>
    </b-row>
    <!-- first name last name input -->
    <b-row class="mb-2">
      <b-col cols="6">
        <label
          for="form-first-name"
          class="form-label"
        >{{ $t('customerpopup.first-name') }}</label>
        <b-input
          type="text"
          v-model="addressDetails.first_name"
          id="form-first-name"
        />
      </b-col>
      <b-col cols="6">
        <label
          for="form-last-name"
          class="form-label"
        >{{ $t('customerpopup.last-name') }}</label>
        <b-input
          type="text"
          v-model="addressDetails.last_name"
          id="form-last-name"
        />
      </b-col>
    </b-row>
    <!-- company input -->
    <b-row class="mb-2">
      <b-col cols="12">
        <label
          for="form-company"
          class="form-label"
        >{{ $t('customerpopup.company') }}</label>
        <b-input
          type="text"
          v-model="addressDetails.company"
          id="form-company"
        />
      </b-col>
    </b-row>
    <!-- address input  and Appartment and suite  input-->
    <b-row class="mb-2">
      <b-col cols="6">
        <label
          for="form-address"
          class="form-label"
        >{{ $t('customerpopup.address') }}</label>
        <b-input
          v-model="addressDetails.address_1"
          id="form-address"
        />
        <span v-if="isAddressMissing"><small class="text-danger error-label">Address required *</small></span>
      </b-col>
      <b-col cols="6">
        <label
          for="form-appartment-suite"
          class="form-label"
        >{{ $t('customerpopup.address-2') }}</label>
        <b-input
          v-model="addressDetails.address_2"
          id="form-appartment-suite"
        />
      </b-col>
    </b-row>

    <!-- city input and postal code input-->
    <b-row class="mb-2">
      <b-col cols="6">
        <label
          for="form-city"
          class="form-label"
        >{{ $t('customerpopup.city') }}</label>
        <b-input
          v-model="addressDetails.city"
          id="form-city"
        />
      </b-col>
      <b-col cols="6">
        <label
          for="form-postal-code"
          class="form-label"
        >{{ $t('customerpopup.zip-code') }}</label>
        <b-input
          v-model="addressDetails.postal_code"
          id="form-postal-code"
        />
      </b-col>
    </b-row>

    <!-- phone input -->
    <b-row class="mb-2">
      <b-col cols="12">
        <label
          for="form-phone"
          class="form-label"
        >{{ $t('customerpopup.phone-number') }}</label>
        <b-input
          v-model="addressDetails.phone"
          id="form-phone"
        />
      </b-col>
    </b-row>

    <!-- default input -->
    <b-row>
      <b-col
        cols="12"
        class="d-flex"
      >
        <label>{{ $t('customerpopup.default-address') }}</label>
        <b-form-checkbox
          class="ml-3"
          v-model="addressDetails.default"
          switch
        />             
      </b-col>
    </b-row>

    <!-- Update address button  -->
    <b-row>
      <b-col
        cols="12"
        v-if="addressAction === 'update'"
      >
        <b-btn
          variant="primary"
          class="float-right"
          @click="updateAddress()"
        >
          {{ $t('customerpopup.update-address-button') }}
        </b-btn>
      </b-col>
      <b-col
        cols="12"
        v-else-if="addressAction ==='add'"
      >
        <b-btn
          variant="primary"
          class="float-right"
          @click="addNewAddress()"
        >
          {{ $t('customerpopup.add-address-button') }}
        </b-btn>
      </b-col>
    </b-row>
  </b-form-group>
</template>
<script>
import {mapGetters} from "vuex"
export default{
    props:{
        selectedAddress:
        {  type:Object,
           default:()=>({})
        },
        addressAction:
        {
            type:String,
            default:'update'
        }
    },
    data:()=>({
        addressDetails:{
        country:null,
        state:null,
        first_name:null,
        last_name:null,
        company:null,
        city:null,
        postal_code:null,
        phone:null,
        address_1:'',
        address_2:'',
        },
        isAddressMissing:false,
        addressSpaceRegex:/^ +$/,
        addressEmptyRegex:/^$/
    }),
    computed:{
        ...mapGetters({
            countryOptions:'customers/countries',
            statesOptions:'customers/usaState'
        })
    },
    mounted(){
        this.addressDetails = JSON.parse(JSON.stringify(this.selectedAddress))
    },
    methods:{
        updateAddress(){
            if(!this.addressDetails.address_1 || this.addressSpaceRegex.test(this.addressDetails.address_1) || this.addressEmptyRegex.test(this.addressDetails.address_1))
            {
                this.isAddressMissing = true
                return
            }
            else
            {
                this.isAddressMissing = false
                this.$emit('updateAddress',this.addressDetails) 
            }
        },
        addNewAddress(){
            if(!this.addressDetails.address_1 || this.addressSpaceRegex.test(this.addressDetails.address_1) || this.addressEmptyRegex.test(this.addressDetails.address_1))
            {
                this.isAddressMissing = true
                return
            }
            else
            {
                this.isAddressMissing = false
                this.$emit('addNewAddress',this.addressDetails)
            }
        }
    },

}
</script>
<style>

.form-label{
    font-size:medium;
    margin-left:2px;
}
.error-label{
    margin-left:2px;
}
</style>